import { createStore } from "vuex";
import { State } from "./types";

const store = createStore<State>({
  state: {
    markdown: "",
  },
  mutations: {
    setMarkdown(state, markdown: string) {
      state.markdown = markdown;
    },
  },
  actions: {
    setMarkdown({ commit }, markdown: string) {
      commit("setMarkdown", markdown);
    },
  },
  getters: {
    markdown: (state) => state.markdown,
  },
});

export default store;
