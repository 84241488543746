<template>
  <div class="not-found">
    <h1>404 - Page Not Found</h1>
    <p>The page you are looking for doesn't exist.</p>
    <router-link to="/">Go back to Home</router-link>
  </div>
</template>

<script lang="ts">
export default {
  name: "NotFoundPage",
};
</script>

<style scoped>
.not-found {
  text-align: center;
  margin-top: 100px;
}

.not-found h1 {
  font-size: 2.5rem;
  color: #ff0000;
}

.not-found p {
  margin: 20px 0;
}

.not-found a {
  color: #00b4cc;
}
</style>
