<template>
  <n-config-provider :theme-overrides="themeOverrides">
    <!-- <div class="uploader">
      <n-upload multiple @change="handleFileUploadChange" class="custom-upload">
        <n-button class="upload-button" text>
          <n-icon>
            <IosAttach />
          </n-icon>
        </n-button>
      </n-upload>
    </div> -->

    <div class="chat-bot">
      <div class="chat-history" style="white-space: pre-line">
        <div
          v-for="(message, index) in chatHistory"
          :key="index"
          class="message"
        >
          <span
            :class="{
              'user-message': message.isUser,
              'bot-message': !message.isUser,
            }"
          >
            {{ message.text }}
          </span>
        </div>
      </div>
      <ul v-if="showFileList">
        <li v-for="(file, index) in files" :key="index">{{ file.name }}</li>
      </ul>
      <div class="user-input">
        <n-input
          v-model:value="userMessage"
          @keyup.enter="sendMessage"
          @input="checkIfFileReference"
          @click="checkIfFileReference"
          placeholder="Message"
          type="textarea"
          size="small"
          :autosize="{
            minRows: 1,
            maxRows: 8,
          }"
          ref="userInput"
        />
      </div>
      <div class="container">
        <n-select
          v-model:value="selectedOrganization"
          filterable
          clearable
          placeholder="Please select a organization"
          :options="organizations"
        />
        <n-button
          tertiary
          type="primary"
          @click="downloadPhoneCanvas"
          :disabled="!selectedOrganization || isLoading"
          >Get Phone Canvas</n-button
        >
        <n-spin v-if="isLoading" size="medium" />
      </div>
    </div>
  </n-config-provider>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { httpPost, httpGet } from "../utils/http_utils";
import type { UploadFileInfo } from "naive-ui";
// import { IosAttach } from "@vicons/ionicons4";
import { GlobalThemeOverrides } from "naive-ui";

const themeOverrides: GlobalThemeOverrides = {
  common: {
    primaryColor: "#00b4cc", // "#E95420",
    primaryColorHover: "#00b4cc", // "#E95420",
    primaryColorPressed: "#00b4cc", // "#E95420",
  },
};

interface ChatMessage {
  text: string;
  isUser: boolean;
}

interface PromptRequestString {
  prompt: string;
}

interface PromptResponseString {
  response: string;
}

interface Organization {
  label: string;
  value: string;
}

export default defineComponent({
  name: "ChatBot",
  data() {
    return {
      userMessage: "",
      chatHistory: [] as ChatMessage[],
      files: [] as File[],
      showFileList: false,
      organizations: this.fetchOrganizations() as Organization[], // Weird solution not sure how it works
      selectedOrganization: null as string | null,
      organizationData: null as any,
      themeOverrides,
      isLoading: false,
    };
  },
  /*components: {
    IosAttach,
  },*/
  methods: {
    checkIfFileReference() {
      let inputElement = this.$refs.userInput as any; // Not sure what type to use here
      const textareaElement = inputElement.$el.querySelector(
        "textarea"
      ) as HTMLTextAreaElement;
      if (
        textareaElement.value[textareaElement.selectionStart - 1] === "@" &&
        this.files.length > 0
      ) {
        this.showFileList = true;
      } else {
        this.showFileList = false;
      }
    },
    async downloadPhoneCanvas() {
      if (!this.selectedOrganization) {
        // Do nothing if no organization is selected
        console.log("No organization selected");
        return;
      }
      const endpoint = `${process.env.VUE_APP_SERVED_URL}/api/content_generation/phone_canvas/${this.selectedOrganization}`;
      try {
        this.isLoading = true;
        const response = await fetch(endpoint, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + sessionStorage.getItem("access_token"),
          },
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        this.isLoading = false;

        const markdownText = await response.text();
        let cleanedMarkdownText = markdownText
          .trim() // Remove leading and trailing whitespace
          .replace(/^\s*"|"(?=\s*$)/g, "") // Remove starting and ending quotes
          .replace(/\s*"\s*$/, "") // Remove ending quotes if surrounded by whitespace
          .replace(/\\n/g, "\n"); // Convert literal \n to actual newlines

        this.$store.dispatch("setMarkdown", cleanedMarkdownText);
        this.$router.push("/markdown");
      } catch (error) {
        console.error("Error fetching phone canvas:", error);
      } finally {
        this.isLoading = false; // Hide spinner
      }
    },
    transformData(data: any[]): { label: string; value: string }[] {
      return data.map((item) => ({
        label: item.OrganisationName,
        value: String(item.OrganisationId),
      }));
    },

    fetchOrganizations() {
      console.log("Fetching organizations...");
      const endpoint = `${process.env.VUE_APP_SERVED_URL}/api/search_service/organizations/fully_scraped`;

      httpGet(
        endpoint,
        (data) => {
          this.organizations = this.transformData(data);
        },
        (error) => {
          console.error("Error fetching organizations:", error);
        },
        { Authorization: "Bearer " + sessionStorage.getItem("access_token") }
      );
    },
    async sendMessage() {
      console.log("User message:", this.userMessage);
      // Add user's message to the chat history
      this.addMessage(this.userMessage, true);

      let endpoint_tmp = `${process.env.VUE_APP_SERVED_URL}/api/process_prompt_with_own_search_new`;
      let request_tmp: string | FormData = JSON.stringify({
        prompt: this.userMessage,
        id: this.selectedOrganization,
      } as PromptRequestString);
      httpPost(
        endpoint_tmp,
        request_tmp,
        (response: PromptResponseString) => {
          this.addMessage(response.response, false);
        },
        (error: any) => {
          console.error("Error fetching bot reply:", error);
        },
        { Authorization: "Bearer " + sessionStorage.getItem("access_token") }
      );
      // Clear the user's message
      this.userMessage = "";
    },
    addMessage(message: string, isUser: boolean) {
      this.chatHistory.unshift({
        text: message,
        isUser,
      } as ChatMessage);
    },
    handleFileUploadChange(data: { fileList: UploadFileInfo[] }) {
      console.log(data.fileList);
      this.files = data.fileList.map((fileInfo) => fileInfo.file as File);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/styles/style.scss";

.container {
  display: flex;
  align-items: center; /* Align items vertically centered */
}

.uploader {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1vh;
  margin-left: auto;
  width: 11vw;
}

.upload-button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  font-size: 50px;
  color: $custom-primary;
}

.chat-bot {
  align-items: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 75vh;
}
.chat-history {
  flex-grow: 1;
  display: flex;
  flex-direction: column-reverse;
  width: 40vw;
  overflow-y: auto;
  margin: 0 auto;
  border-radius: 10px;
  border: 1px solid $custom-primary;
  padding: 10px;
}

.message {
  font-size: medium;
  margin-bottom: 10px;
  display: flex;
}

.user-message {
  background-color: white;
  color: $custom-primary;
  padding: 8px;
  border-radius: 5px;
  max-width: 80%;
  text-align: left;
}

.bot-message {
  background-color: $custom-primary;
  color: white;
  padding: 8px;
  border-radius: 5px;
  max-width: 80%;
  margin-left: auto;
  text-align: left;
}

.user-input {
  display: flex;
  margin-top: 10px;
  width: 31vw;
  text-align: left;
}

input {
  flex: 1;
  font-size: medium;
  padding: 8px;
  border: 1px solid $custom-primary;
  color: $custom-secondary;
  border-radius: 5px 5px 5px 5px;
}

button {
  font-size: medium;
  padding: 8px;
  background-color: $custom-primary;
  color: #fff;
  border: 1px solid $custom-primary;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
}

button:hover {
  background-color: $custom-secondary;
  color: $custom-primary;
}

.green-text {
  color: $custom-primary;
  font-weight: bold;
  font-size: 34px;
}
</style>
