<template>
  <div id="app">
    <img alt="logo" src="./assets/logo.png" width="20%" style="margin: 1%" />
    <!-- <SideBar></SideBar> -->
    <router-view></router-view>
    <!-- This will render the routed component -->
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// import SideBar from "./components/SideBar.vue";

export default defineComponent({
  name: "App",
  /*components: {
    SideBar,
  },*/
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #00b4cc;
  margin-top: 60px;
}
</style>
