import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "chat-bot" }
const _hoisted_2 = {
  class: "chat-history",
  style: {"white-space":"pre-line"}
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "user-input" }
const _hoisted_5 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_input = _resolveComponent("n-input")!
  const _component_n_select = _resolveComponent("n-select")!
  const _component_n_button = _resolveComponent("n-button")!
  const _component_n_spin = _resolveComponent("n-spin")!
  const _component_n_config_provider = _resolveComponent("n-config-provider")!

  return (_openBlock(), _createBlock(_component_n_config_provider, { "theme-overrides": _ctx.themeOverrides }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.chatHistory, (message, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "message"
            }, [
              _createElementVNode("span", {
                class: _normalizeClass({
              'user-message': message.isUser,
              'bot-message': !message.isUser,
            })
              }, _toDisplayString(message.text), 3)
            ]))
          }), 128))
        ]),
        (_ctx.showFileList)
          ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.files, (file, index) => {
                return (_openBlock(), _createElementBlock("li", { key: index }, _toDisplayString(file.name), 1))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_n_input, {
            value: _ctx.userMessage,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.userMessage) = $event)),
            onKeyup: _withKeys(_ctx.sendMessage, ["enter"]),
            onInput: _ctx.checkIfFileReference,
            onClick: _ctx.checkIfFileReference,
            placeholder: "Message",
            type: "textarea",
            size: "small",
            autosize: {
            minRows: 1,
            maxRows: 8,
          },
            ref: "userInput"
          }, null, 8, ["value", "onKeyup", "onInput", "onClick"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_n_select, {
            value: _ctx.selectedOrganization,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedOrganization) = $event)),
            filterable: "",
            clearable: "",
            placeholder: "Please select a organization",
            options: _ctx.organizations
          }, null, 8, ["value", "options"]),
          _createVNode(_component_n_button, {
            tertiary: "",
            type: "primary",
            onClick: _ctx.downloadPhoneCanvas,
            disabled: !_ctx.selectedOrganization || _ctx.isLoading
          }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Get Phone Canvas")
            ])),
            _: 1
          }, 8, ["onClick", "disabled"]),
          (_ctx.isLoading)
            ? (_openBlock(), _createBlock(_component_n_spin, {
                key: 0,
                size: "medium"
              }))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["theme-overrides"]))
}