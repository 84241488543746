<template>
  <div class="login-container">
    <form @submit.prevent="handleSubmit">
      <div>
        <label for="username">Username</label>
        <input id="username" v-model="form.username" type="text" required />
      </div>

      <div>
        <label for="password">Password</label>
        <input id="password" v-model="form.password" type="password" required />
      </div>

      <button type="submit">Login</button>

      <div v-if="errorMessage" class="error">{{ errorMessage }}</div>
    </form>
  </div>
</template>

<script lang="ts">
import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
  setup() {
    const form = ref({
      username: "",
      password: "",
    });

    const errorMessage = ref<string | null>(null);
    const router = useRouter();

    const handleSubmit = async () => {
      try {
        // Make a request to the backend for authentication
        const response = await fetch(
          `${process.env.VUE_APP_SERVED_URL}/api/user_management/token`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams({
              username: form.value.username,
              password: form.value.password,
            }),
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.detail || "An unexpected error occurred");
        }

        // Parse the response and extract the JWT token
        const { access_token } = await response.json();

        // Store the JWT token in sessionStorage (or use localStorage if preferred)
        sessionStorage.setItem("access_token", access_token);

        // Redirect to a protected page (e.g., the home page)
        router.push("/");
      } catch (error: any) {
        // Display error message if login fails
        errorMessage.value = error.message || "Login failed. Please try again.";
      }
    };

    return {
      form,
      errorMessage,
      handleSubmit,
    };
  },
};
</script>

<style scoped>
.login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 2rem;
  border: 1px solid #ddd;
  border-radius: 8px;
}

input {
  width: 100%;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button {
  width: 100%;
  padding: 0.75rem;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

.error {
  color: red;
  margin-top: 1rem;
}
</style>
