export function httpGet(
  url: string,
  callback: (data: any) => void,
  errorCallback?: (error: Error) => void,
  additionalHeaders: { [key: string]: string } = {}
) {
  try {
    fetch(url, {
      method: "GET",
      mode: "cors",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        ...additionalHeaders,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        callback(data);
      })
      .catch((error: Error) => {
        console.error("Fetch Error:", error);
        // Rethrow the error to be caught by the outer try...catch
        throw error;
      });
  } catch (error: any) {
    console.error("Error fetching data:", error);
    if (errorCallback) {
      errorCallback(error);
    }
  }
}

export function httpPost(
  url: string,
  body: any,
  callback: (data: any) => void,
  errorCallback?: (error: Error) => void,
  additionalHeaders: { [key: string]: string } = {}
) {
  try {
    const headers = {
      ...additionalHeaders,
    };
    if (!(body instanceof FormData)) {
      headers["Content-Type"] = "application/json"; // Set content type for JSON
    }
    fetch(url, {
      method: "POST",
      mode: "cors",
      credentials: "same-origin",
      body: body,
      headers: headers,
    })
      .then((response) => response.json())
      .then((data) => {
        callback(data);
      })
      .catch((error: Error) => {
        console.error("Fetch Error:", error);
        // Rethrow the error to be caught by the outer try...catch
        throw error;
      });
  } catch (error: any) {
    console.error("Error fetching data:", error);
    if (errorCallback) {
      errorCallback(error);
    }
  }
}

export function submitInterfaceData(interface_data: any, url: string): void {
  try {
    if (interface_data) {
      httpPost(
        url,
        JSON.stringify(interface_data),
        (data) => {
          console.log("Config submitted:", data);
          interface_data = data;
        },
        (error) => {
          console.error("Error submitting config:", error);
        },
        { "Content-Type": "application/json" }
      );
    } else {
      console.error("No config data available.");
    }
  } catch (error) {
    console.error("Error submitting config:", error);
  }
}
