import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import MainPage from "../components/MainPage.vue";
import MarkdownPage from "../components/MarkdownPage.vue";
import LoginPage from "../components/LoginPage.vue";
import NotFoundPage from "../components/NotFoundPage.vue";

const routes: Array<RouteRecordRaw> = [
  { path: "/", component: MainPage, meta: { requiresAuth: true } },
  { path: "/login", component: LoginPage },
  {
    path: "/markdown",
    component: MarkdownPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFoundPage,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

async function isAuthenticated() {
  const token = sessionStorage.getItem("access_token");
  if (!token) return false;

  try {
    const response = await fetch(
      `${process.env.VUE_APP_SERVED_URL}/api/user_management/me`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.ok) {
      return true;
    }
  } catch (error) {
    console.error("Authentication check failed:", error);
  }
  return false;
}

// Global navigation guard
router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    const authenticated = await isAuthenticated(); // Wait for the async check
    if (!authenticated) {
      next({ path: "/login" }); // Redirect to login if not authenticated
    } else {
      next(); // Allow access if authenticated
    }
  } else {
    next(); // Allow access to public pages
  }
});

export default router;
