<template>
  <div class="markdown-container" v-html="renderedMarkdown"></div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import MarkdownIt from "markdown-it";

export default defineComponent({
  name: "MarkdownPage",
  setup() {
    const store = useStore();
    const md = new MarkdownIt();

    // Cast the store to the State type
    const renderedMarkdown = computed(() => {
      const markdown = store.getters.markdown as string;
      return md.render(markdown || "");
    });

    return {
      renderedMarkdown,
    };
  },
});
</script>

<style scoped>
.markdown-container {
  text-align: left; /* Align text to the left */
  margin: 0 auto; /* Center container if necessary */
  max-width: 800px; /* Optional: limit the width of the content */
  color: black; /* Set text color to black */
}
</style>
