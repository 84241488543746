import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "login-container" }
const _hoisted_2 = {
  key: 0,
  class: "error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => ($setup.handleSubmit && $setup.handleSubmit(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", null, [
        _cache[3] || (_cache[3] = _createElementVNode("label", { for: "username" }, "Username", -1)),
        _withDirectives(_createElementVNode("input", {
          id: "username",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.username) = $event)),
          type: "text",
          required: ""
        }, null, 512), [
          [_vModelText, $setup.form.username]
        ])
      ]),
      _createElementVNode("div", null, [
        _cache[4] || (_cache[4] = _createElementVNode("label", { for: "password" }, "Password", -1)),
        _withDirectives(_createElementVNode("input", {
          id: "password",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.password) = $event)),
          type: "password",
          required: ""
        }, null, 512), [
          [_vModelText, $setup.form.password]
        ])
      ]),
      _cache[5] || (_cache[5] = _createElementVNode("button", { type: "submit" }, "Login", -1)),
      ($setup.errorMessage)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.errorMessage), 1))
        : _createCommentVNode("", true)
    ], 32)
  ]))
}